import React from "react"
import useTranslations from "../../utils/useTranslations"
import securityLink from "../../images/contact-us/securityLink.png"
import legalLink from "../../images/contact-us/legalLink.png"
import jobsLink from "../../images/contact-us/jobsLink.png"
import pressLink from "../../images/contact-us/pressLink.png"
import bizdevLink from "../../images/contact-us/bizdevLink.png"
import adsalesLink from "../../images/contact-us/adsalesLink.png"
import heroBg from '../../images/company/adobe-stock-278687682.jpg'
import HelpCenterLinks from "../../components/help-center/helpCenterLinks"

const CompanyContactUs = ({ pageContext: {text} }) => {
const t = useTranslations(text)
  
const topSectionContainer = {
		backgroundImage:`url(${heroBg})` ,
		backgroundSize:`cover`
	}
	const HelpCenterHome = HelpCenterLinks.homeLink;


  return (
  	<>
  		<div className="top-section-container" style={topSectionContainer}>
			<div className="text-center py-5" >
			<div className="container py-5" >
				<h1 className="h1 m-0 pt-5 pb-4 text-white">{t(`Get in Touch in BitTorrent`)}</h1>
			</div>
			</div>
		</div>
	    <div className="container my-5 pb-3">

	      <div className="row">
	      	
	      <div className="col-12  ">
	      	<h2>{t("BitTorrent Department Contacts")}</h2>
	      	<p>
	      		{t("Want to reach someone in a specific department at BitTorrent? Please use the contact methods below to route your email to the appropriate person in ad sales, partnerships, press, jobs, legal, or to report a vulnerability with our website or one of our products.")}
	      	</p>
	      	<p>
	      		{t(`<span class='font-weight-bold'>Looking for help?</span> For Premium product related questions, please visit our <a id='company-contactus-help-link' href="${HelpCenterLinks.homeLink}" target='_blank' rel='noopener noreferrer'> Help page</a>.`)}
	      	</p>
		      	<div className="mt-4">
		      		<p className="mb-0">{t("Advertising")}</p>
		      		<img src={adsalesLink} alt="adsales email" className="security-email"></img>
		      	</div>
		      	<div className="mt-4">
		      		<p className="mb-0">{t("Partnerships")}</p>
		      		<img src={bizdevLink} alt="bizdev email" className="security-email"></img>
		      	</div>
		      	<div className="mt-4">
		      		<p className="mb-0">{t("Press")}</p>
		      		<img src={pressLink} alt="press email" className="security-email"></img>
		      	</div>
		      	<div className="mt-4">
		      		<p className="mb-0">{t("Jobs")}</p>
		      		<img src={jobsLink} alt="jobs email" className="security-email"></img>
		      	</div>
		      	<div className="mt-4 ">
		      		<p className="mb-0">{t("Legal")}</p>
		      		<img src={legalLink} alt="legal email" className="security-email"></img>
		      	</div>
		      	<div className="mt-4">
		      		<p className="mb-0">{t("Product or site vulnerability reports")}</p>
		      		<img src={securityLink} alt="security email" className="security-email"></img>
		      	</div>
			<div className="mt-4">
				<p className="mb-0"><b>+1-628-253-1020</b>. Please note that this phone number does not service customer support requests.</p>
			</div>

		      </div>

	      </div>
	    </div>
	</>
  )
}

export default CompanyContactUs
